$primary-green: #00B1B2;
$primary-grey:#E3E3E3;
$primary-dark-grey: #CACDD3;
$primary-dark-blue: #192956;
$dropzone-bg:#E4F6F691;
$box-shadow:#1BB1B224;
$mat-card-box-shadow:#1BB1B229;
$error-red: #f18071;
$light-blue: #559EFE;
$black:black;
$light-grey: #A0A0A0;
$h-green: #00B3B4;
$bg-light-glue: #edf8f9;
