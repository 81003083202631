mat-form-field.mat-form-field:not(.title-form-control):not(.search-box-taxons):not(.search-box-comments):not(.search-box-changes):not(.reply-form-field):not(.mat-form-field-search):not(.mat-form-field-select-pill):not(.width-select) {
  display: block;

  .mat-form-field-wrapper {
    padding-bottom: 24px;

    /* Global */
    .mat-form-field-flex {
      font-size: 14px;
      line-height: 22px;
      background: var(--color-ffffff);
      color: var(--color-676767);
      border: 1px solid var(--color-e3e3e3);
      border-radius: 6px;
      transition: all 0.2s ease-in-out;
      padding: 6px 12px 0 12px;
      letter-spacing: 0;

      .mat-form-field-infix {
        padding: 0 0 6px 0;
        border-top: 0;

        .mat-form-field-label-wrapper {
          display: none;
        }

        > input {
          &::-webkit-input-placeholder {
            display: block !important;
          }

          &::placeholder {
            display: block !important;
          }
        }

        > textarea {
          resize: none;
        }
      }
    }

    /* Underline */
    .mat-form-field-underline {
      display: none;
    }

    .mat-form-field-subscript-wrapper {
      margin: -2px 0 0 0;
      letter-spacing: 0;
      padding: 0;

      mat-error {
        font-size: 13px;
        font-weight: 600;
        line-height: 16px;
        color: var(--color-e56b87);
      }
    }
  }

  /* Select */
  &.mat-form-field-type-mat-select {
    .mat-form-field-wrapper {
      .mat-form-field-flex {
        padding: 6px 8px 0 12px;

        .mat-form-field-infix {
          .mat-select-arrow-wrapper {
            transform: none;
          }
        }
      }
    }
  }

  /* Focus */
  &.mat-focused {
    .mat-form-field-wrapper {
      .mat-form-field-flex {
        border-color: var(--color-00b1b2);
      }
    }
  }

  /* Error */
  &.mat-form-field-invalid {
    .mat-form-field-wrapper {
      .mat-form-field-flex {
        border-color: var(--color-e56b87);

        .mat-form-field-infix {
          .mat-form-field-label {
            color: var(--color-e56b87);
          }
        }
      }
    }
  }

  /* Disabled */
  &.mat-form-field-disabled {
    .mat-form-field-wrapper {
      .mat-form-field-flex {
        background: var(--color-f1f2f2);
      }

      .mat-select-value, .mat-select-arrow {
        color: var(--color-818181);
      }
    }
  }

  &.import-document-section-select {
    .mat-form-field-wrapper {
      padding-bottom: 16px;

      .mat-form-field-flex {
        padding: 8px 8px 4px 8px;
      }
    }
  }

  &.no-bottom-padding {
    .mat-form-field-wrapper {
      padding-bottom: 0;
    }
  }
}

.upper-label {
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  margin: 0 0 8px 0;
  color: var(--color-192956);
}

.pm-editor-override {
  .ProseMirror {
    font-size: 14px;
    line-height: 22px;
    min-height: 80px;
    max-height: 80px;
    background: var(--color-ffffff);
    border: 1px solid var(--color-e3e3e3);
    border-radius: 6px;
    transition: all 0.2s ease-in-out;
    padding: 6px 12px;
    letter-spacing: 0;
    overflow-y: scroll;

    .ProseMirror__placeholder {
      margin-top: 0;

      &::after {
        color: var(--color-cacdd3);
      }
    }

    &.ProseMirror-focused {
      border-color: var(--color-00b1b2);
    }
  }
}

mat-formio-textfield, mat-formio-textarea {
  margin: 0 !important;
}
